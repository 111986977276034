import { IUser, SegmentDataLayer, SpecWindow } from "../types";
import Cookies from "js-cookie";

function trackWithSegments() {
  return !Cookies.get("admin") && "analytics" in window;
}

export function segmentsIdentify(userData: IUser, responseData: any) {
  if (!trackWithSegments()) {
    return;
  }

  const { userType, firstName, lastName, email, companyName } = userData;
  const { ro_id: id, created_at: created_ts } = responseData;

  const segmentDataLayer: SegmentDataLayer = {
    state: "logged in",
    email,
    first_name: firstName,
    last_name: lastName,
    firstName,
    lastName,
    name: `${firstName} ${lastName}`,
    id,
    type: userType!,
    company_name: companyName!,
    company: companyName!,
    created: new Date(created_ts * 1_000).toISOString(),
    created_ts,
  };

  (window as SpecWindow).analytics.identify?.(id, segmentDataLayer);
}

export function segmentsTrack(eventName: string) {
  if (!trackWithSegments()) {
    return;
  }

  (window as SpecWindow).analytics.track?.(eventName);
}
