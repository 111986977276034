import { useContext, useState, useEffect, useRef } from "react";
import { Alert, Box, Button, CircularProgress } from "@mui/material";
import * as Yup from "yup";
import { MuiOtpInput } from "mui-one-time-password-input";

import { AppContext } from "../../../context/AppContext";
import { UserType } from "../../../types";
import { useAxios, useCustomFormik } from "../../../hooks";
import {
  expiredStorage,
  LS_PHONE_VERIFY_DISABLE,
  RESEND_DISABLE_PERIOD,
} from "../thirdStepForm/PhoneVerifyForm";
import { getSecondsString } from "../../../utils";

const FormSchema = Yup.object().shape({
  smsVerificationCode: Yup.string()
    .length(6, "Your verification code should be 6 digits.")
    .required("This value is required."),
});

function CodeVerificationForm() {
  const intervalId = useRef<NodeJS.Timeout>();
  const { userData, currentStep, setCurrentStep } = useContext(AppContext);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const { requestApi } = useAxios();
  const formik = useCustomFormik({
    initialValues: {
      smsVerificationCode: "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      const body = {
        sms_verification_code: values.smsVerificationCode,
      };
      setLoading(true);
      requestApi("post", "/users/verify_sms_code", body)
        .then(() => {
          expiredStorage.setItem(
            LS_PHONE_VERIFY_DISABLE,
            "",
            RESEND_DISABLE_PERIOD,
          );
          setCount((_) => RESEND_DISABLE_PERIOD);
          setCurrentStep(currentStep + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  useEffect(() => {
    if (userData.userType === UserType.PUBLISHER && currentStep === 3) {
      expiredStorage.clearExpired();
      const timeLeft = expiredStorage.getTimeLeft(LS_PHONE_VERIFY_DISABLE);
      if (!!timeLeft && timeLeft > 0) {
        setCount(timeLeft);
        startInterval();
      }
    } else {
      setCount(0);
    }
  }, [currentStep, userData]);

  useEffect(() => {
    if (count <= 0) stopInterval();
  }, [count]);

  const startInterval = () => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);
    intervalId.current = interval;
  };

  const stopInterval = () => {
    if (!intervalId.current) return;
    clearInterval(intervalId.current);
    intervalId.current = undefined;
  };

  const handleResend = () => {
    const body = {
      phone_number: userData.phoneNumber,
    };
    requestApi("post", "/users/send_sms_code", body).then(() => {
      expiredStorage.setItem(
        LS_PHONE_VERIFY_DISABLE,
        "",
        RESEND_DISABLE_PERIOD,
      );
      setCount(RESEND_DISABLE_PERIOD);
      startInterval();
      setShowConfirmMessage(true);
      setTimeout(() => {
        setShowConfirmMessage(false);
      }, 3000);
    });
  };

  return (
    <Box className="space-y-5">
      <div className="text-blue-900 text-xxl leading-5 font-bold pb-2">
        Verification Code
      </div>
      <div className="text-grey-600">
        {`We just sent a verification code to ${userData.phoneNumber} `}
        <span
          className="text-blue-500 cursor-pointer text-sm"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          [edit]
        </span>
      </div>
      <form className="w-full space-y-5" onSubmit={formik.handleSubmit}>
        <div>
          <MuiOtpInput
            length={6}
            value={formik.values.smsVerificationCode}
            TextFieldsProps={{
              size: "small",
              className: "bg-blue-200",
              type: "tel",
              inputProps: { sx: { pl: 0, pr: 0 } },
            }}
            onChange={(value) =>
              formik.setFieldValue("smsVerificationCode", value)
            }
          />
          {formik.errors.smsVerificationCode &&
            formik.touched.smsVerificationCode && (
              <div className="text-red text-xs pt-1 text-left">
                {formik.errors.smsVerificationCode.toString()}
              </div>
            )}
        </div>
        <Button
          type="submit"
          className="!normal-case"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress size={12} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          Continue
        </Button>
        {showConfirmMessage && (
          <Alert severity="success">Successfully resent code!</Alert>
        )}
        <Button
          className="!normal-case"
          variant="text"
          fullWidth
          size="large"
          onClick={handleResend}
          disabled={count !== 0 || loading}
        >
          Resend Code {count > 0 ? `in ${getSecondsString(count)}` : ""}
        </Button>
      </form>
    </Box>
  );
}

export default CodeVerificationForm;
