import { useContext, useEffect, useState } from "react";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import * as Yup from "yup";

import { AppContext } from "../../context";
import { useAxios, useCustomFormik } from "../../hooks";
import FormCaption from "./FormCaption";

const FormSchema = Yup.object().shape({
  companyName: Yup.string().required("This value is required."),
  url: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "This value should be a valid url.",
    )
    .required("This value is required."),
});

function CompanyDetailsForm() {
  const { userData, currentStep, setUserData, setCurrentStep } =
    useContext(AppContext);
  const { requestApi } = useAxios();
  const [loading, setLoading] = useState(false);

  const formik = useCustomFormik({
    initialValues: {
      companyName: "",
      url: "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      const body = {
        company_name: values.companyName,
        url: values.url,
        goals: userData.goals,
        budget: userData.budget,
      };
      setLoading(true);
      requestApi("patch", "/users/onboarding", body)
        .then(() => {
          setUserData({
            ...userData,
            ...values,
          });
          setCurrentStep(currentStep + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (userData.companyName) {
      formik.setFieldValue("companyName", userData.companyName);
    }
    if (userData.url) {
      formik.setFieldValue("url", userData.url);
    }
    // TODO: we don not really need this useEffect,
    //  we can merge data only before submit
    // eslint-disable-next-line
  }, [userData.companyName, userData.url /*formik*/]);

  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line
  }, [formik.values]);

  return (
    <Box>
      <FormCaption />
      <form className="w-full space-y-5" onSubmit={formik.handleSubmit}>
        <div className="flex flex-col sm:flex-row gap-5">
          <TextField
            name="companyName"
            label="Company name"
            value={formik.values.companyName}
            fullWidth
            error={!!formik.errors.companyName && !!formik.touched.companyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.errors.companyName && formik.touched.companyName
                ? formik.errors.companyName.toString()
                : ""
            }
          />
          <TextField
            name="url"
            label="Website"
            value={formik.values.url}
            fullWidth
            error={!!formik.errors.url && !!formik.touched.url}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.errors.url && formik.touched.url
                ? formik.errors.url.toString()
                : ""
            }
          />
        </div>
        <Button
          type="submit"
          className="!normal-case"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress size={12} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          Continue
        </Button>
      </form>
    </Box>
  );
}

export default CompanyDetailsForm;
