import React from "react";
import "./App.scss";
import { Provider } from "@rollbar/react";
import AppSignUp from "./AppSignUp";
import AppRequestDemo from "./AppRequestDemo";

function isRollBarEnabled() {
  const env = (process.env.REACT_APP_APP_ENV ?? "").toLowerCase();

  return env === "production" || env === "staging";
}

export type AppId = "sign_up_app" | "request_demo_app";

function App({ appIds }: { appIds: AppId[] }) {
  const rollbarConfig = {
    enabled: isRollBarEnabled(),
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.REACT_APP_APP_ENV,
      client: {
        javascript: {
          code_version: "1.0.0",
          source_map_enabled: true,
        },
      },
    },
  };

  return !!appIds.length ? (
    <Provider config={rollbarConfig}>
      {appIds.includes("sign_up_app") && <AppSignUp />}
      {appIds.includes("request_demo_app") && (
        <div id="request-demo_overlay">
          <AppRequestDemo />
        </div>
      )}
    </Provider>
  ) : null;
}

export default App;
