import React, { useContext, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import StepFormWrapper from "./signupForms/StepFormWrapper";
import UserDetailsForm from "./signupForms/UserDetailsForm";
import CompanyDetailsForm from "./signupForms/CompanyDetailsForm";
import ThirdStepForm from "./signupForms/thirdStepForm";
import FourthStepForm from "./signupForms/fourthStepForm";
import NewsLetterDetailsForm from "./signupForms/NewsLetterDetailsForm";
import { Box } from "@mui/material";
import { AppContext } from "../context";
import { useAxios } from "../hooks";

export default function AppSignUp() {
  const { currentStep, onboardingValues, setOnboardingValues } =
    useContext(AppContext);

  const { requestApi } = useAxios();

  useEffect(() => {
    if (!onboardingValues) {
      requestApi("get", "/users/onboarding_values").then((data) => {
        setOnboardingValues(data);
      });
    }
    // FIXME: requestApi must be a singleton
    // eslint-disable-next-line
  }, [onboardingValues, /*requestApi,*/ setOnboardingValues]);

  return (
    <Box
      className="App text-center"
      sx={{
        minWidth: { xs: "auto", sm: "400px" },
        maxWidth: { xs: "auto", sm: "540px" },
      }}
    >
      <SwipeableViews index={currentStep} disabled={true}>
        <StepFormWrapper>
          <UserDetailsForm />
        </StepFormWrapper>
        <StepFormWrapper>
          <CompanyDetailsForm />
        </StepFormWrapper>
        <StepFormWrapper>
          <ThirdStepForm />
        </StepFormWrapper>
        <StepFormWrapper>
          <FourthStepForm />
        </StepFormWrapper>
        <StepFormWrapper>
          <NewsLetterDetailsForm />
        </StepFormWrapper>
      </SwipeableViews>
    </Box>
  );
}
