import { useContext, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";

import { AppContext } from "../../context";
import { UserType } from "../../types";
import { useAxios, useCustomFormik } from "../../hooks";
import FormCaption from "./FormCaption";
import { segmentsIdentify, segmentsTrack } from "../../utils/analytics";
import PasswordPopper from "./PasswordPopper";
import noop from "lodash/noop";

const forbiddenEmailDomains: string[] = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "mail.ru",
  "aol.com",
  "icloud.com",
  "protonmail.com",
  "me.com",
  "proton.me",
  "mailinator.com",
  "live.com",
  "hey.com",
  "hotmail.co.uk",
  "gmx.de",
];

const notPublicServiceEmail = (value: string | undefined) => {
  const domain = value?.split("@")[1] ?? "";
  return !forbiddenEmailDomains.includes(domain.toLowerCase());
};

const FormSchema = Yup.object().shape({
  userType: Yup.string().required("This value is required."),
  firstName: Yup.string().required("This value is required."),
  lastName: Yup.string().required("This value is required."),
  email: Yup.string()
    .email("This value should be a valid email.")
    .when("userType", {
      is: (userType: UserType) => userType === UserType.ADVERTISER,
      then: Yup.string().test(
        "not-public-service",
        "Personal email addresses can't be used for Advertiser accounts.",
        notPublicServiceEmail,
      ),
    })
    .required("This value is required."),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(128, "Password must be at most 128 characters")
    .matches(/[0-9]/, "Password must contain at least one digit")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[\W_]/, "Password must contain at least one symbol"),
});

const userTypes = [
  {
    value: UserType.ADVERTISER,
    label: "Advertiser",
  },
  {
    value: UserType.PUBLISHER,
    label: "Publisher",
  },
];

function UserDetailsForm() {
  const { userData, currentStep, setUserData, setCurrentStep } =
    useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  const { requestApi, setAuthToken } = useAxios();
  const [loading, setLoading] = useState(false);
  const formik = useCustomFormik({
    initialValues: {
      userType: undefined,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      const body = {
        email: values.email,
        password: values.password,
        client_id: process.env.REACT_APP_CLIENT_ID,
        user_type: values.userType,
        first_name: values.firstName,
        last_name: values.lastName,
      };
      setLoading(true);
      requestApi("post", "/users", body)
        .then((responseData) => {
          const newUserData = {
            ...userData,
            ...values,
          };
          setUserData(newUserData);
          segmentsIdentify(newUserData, responseData);
          segmentsTrack(`${values.userType}_signed_up`);
          setAuthToken(responseData.access_token);
          setCurrentStep(currentStep + 1);
        })
        .catch(noop)
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const setCompanyData = (email: string) => {
    requestApi(
      "get",
      `/users/company_lookup?email=${encodeURIComponent(email)}`,
    ).then((data) => {
      setUserData({
        ...userData,
        companyName: data.company_name,
        url: data.domain,
      });
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBlur = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <Box>
      <FormCaption />
      <form className="w-full space-y-5" onSubmit={formik.handleSubmit}>
        <TextField
          name="userType"
          select
          label="Account Type"
          fullWidth
          value={formik.values.userType}
          error={!!formik.errors.userType && !!formik.touched.userType}
          onChange={(event) => {
            formik.handleChange(event);
            setUserData({
              ...userData,
              [event.target.name]: event.target.value,
            });
          }}
          onBlur={formik.handleBlur}
          helperText={
            formik.errors.userType && formik.touched.userType
              ? formik.errors.userType.toString()
              : ""
          }
        >
          {userTypes?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <div className="flex flex-col sm:flex-row gap-5">
          <TextField
            name="firstName"
            label="First Name"
            fullWidth
            value={formik.values.firstName}
            error={!!formik.errors.firstName && !!formik.touched.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.errors.firstName && formik.touched.firstName
                ? formik.errors.firstName.toString()
                : ""
            }
          />
          <TextField
            name="lastName"
            label="Last Name"
            fullWidth
            value={formik.values.lastName}
            error={!!formik.errors.lastName && !!formik.touched.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.errors.lastName && formik.touched.lastName
                ? formik.errors.lastName.toString()
                : ""
            }
          />
        </div>
        <TextField
          name="email"
          type="email"
          label={
            formik.values.userType === UserType.ADVERTISER
              ? "Work Email"
              : "Email"
          }
          fullWidth
          value={formik.values.email}
          error={!!formik.errors.email && !!formik.touched.email}
          onChange={formik.handleChange}
          onBlur={(e) => {
            formik.handleBlur(e);
            if (!formik.errors.email) setCompanyData(formik.values.email);
          }}
          helperText={
            formik.errors.email && formik.touched.email
              ? formik.errors.email.toString()
              : ""
          }
        />
        <PasswordPopper
          id={id}
          open={open}
          anchorEl={anchorEl}
          value={formik.values.password}
        />
        <div aria-describedby={id} onClick={handleClick}>
          <TextField
            name="password"
            type={showPassword ? "text" : "password"}
            label="Password"
            fullWidth
            autoComplete="new-password"
            value={formik.values.password}
            error={!!formik.errors.password && !!formik.touched.password}
            onChange={formik.handleChange}
            onBlur={(e) => {
              formik.handleBlur(e);
              handleBlur();
            }}
            helperText={
              formik.errors.password && formik.touched.password
                ? formik.errors.password.toString()
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Button
          type="submit"
          className="!normal-case"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress size={12} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          Create Account
        </Button>
      </form>
    </Box>
  );
}

export default UserDetailsForm;
