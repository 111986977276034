import React from "react";
import { Box, BoxProps } from "@mui/material";

const StepFormWrapper: React.FC<BoxProps> = ({ children }) => {
  return (
    <Box
      sx={{
        p: {
          xs: 0,
          sm: "2.5rem",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default StepFormWrapper;
