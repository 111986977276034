import React, { useContext, useMemo, useState } from "react";
import { AppContext } from "../../context";
import { useAxios, useCustomFormik } from "../../hooks";
import { Box, Button, CircularProgress } from "@mui/material";
import FormCaption from "./FormCaption";
import * as Yup from "yup";
import FormInputItem from "./FormInputItem";

const FormSchema = Yup.object().shape({
  newsletter_name: Yup.string().required("This value is required."),
  subscribers: Yup.string().required("This value is required."),
  category: Yup.string().required("This value is required."),
  frequency: Yup.string().required("This value is required."),
});

export default function NewsLetterDetailsForm() {
  const { userData, onboardingValues, setUserData } = useContext(AppContext);
  const { requestApi } = useAxios();
  const [loading, setLoading] = useState(false);

  const frequencyOptions = useMemo(() => {
    return onboardingValues
      ? onboardingValues.frequency.map((item) => ({
          value: item,
          label: item,
        }))
      : [];
  }, [onboardingValues]);

  const topicOptions = useMemo(() => {
    return onboardingValues
      ? onboardingValues.categories.map((item) => ({
          value: item,
          label: item,
        }))
      : [];
  }, [onboardingValues]);

  const subscribersQtyOptions = useMemo(() => {
    return onboardingValues
      ? onboardingValues.subscribers.map((item) => ({
          value: item,
          label: item,
        }))
      : [];
  }, [onboardingValues]);

  const formik = useCustomFormik({
    initialValues: {
      newsletter_name: "",
      subscribers: "",
      category: "",
      frequency: "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      setLoading(true);
      localStorage.setItem("onboardingNewsLetterName", values.newsletter_name);
      requestApi("patch", "/users/onboarding", values)
        .then(() => {
          const host =
            (process.env.REACT_APP_APP_ENV ?? "").toLowerCase() === "production"
              ? "paved.com"
              : "staging.paved.com";
          return window.location.replace(`https://${host}/publisher/sites`);
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    formik.handleChange(event);
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  const getHelperText = (name: string) =>
    formik.errors[name] && formik.touched[name]
      ? formik.errors[name]?.toString()
      : "";

  const getError = (name: string) =>
    !!formik.errors[name] && !!formik.touched[name];

  return (
    <Box>
      <FormCaption />
      <form className="w-full space-y-5" onSubmit={formik.handleSubmit}>
        <FormInputItem
          name="newsletter_name"
          value={formik.values.newsletter_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={getHelperText("newsletter_name")}
          select={false}
          error={getError("newsletter_name")}
          caption="What is your newsletter name?"
          placeholder="Enter name"
        />
        <FormInputItem
          name="subscribers"
          value={formik.values.subscribers}
          error={getError("subscribers")}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          helperText={getHelperText("subscribers")}
          options={subscribersQtyOptions}
          caption="How many subscribers do you have?"
          select={true}
        />
        <FormInputItem
          name="category"
          value={formik.values.category}
          error={getError("category")}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          helperText={getHelperText("category")}
          options={topicOptions}
          caption="Select the topic of your newsletter"
          select={true}
        />
        <FormInputItem
          name="frequency"
          value={formik.values.frequency}
          error={getError("frequency")}
          onChange={handleChange}
          onBlur={formik.handleBlur}
          helperText={getHelperText("frequency")}
          options={frequencyOptions}
          caption="How frequently do you send your newsletter?"
          select={true}
        />
        <Button
          type="submit"
          className="!normal-case"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress size={12} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          Continue
        </Button>
      </form>
    </Box>
  );
}
