import React, { createContext, ReactNode, useEffect, useState } from "react";
import noop from "lodash/noop";
import { IError, IOnboardingValues, IUser } from "../types";

interface IAppContext {
  userData: IUser;
  setUserData: (data: IUser) => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  onboardingValues: IOnboardingValues | undefined;
  setOnboardingValues: (values: IOnboardingValues) => void;
  errors: IError;
  setErrors: (error: IError) => void;
}

const DEFAULT_STATE: IAppContext = {
  userData: {
    userType: undefined,
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  },
  setUserData: noop,
  currentStep: 0,
  setCurrentStep: noop,
  onboardingValues: undefined,
  setOnboardingValues: noop,
  errors: {},
  setErrors: noop,
};

export const AppContext = createContext(DEFAULT_STATE);

export interface IAppProviderProps {
  children?: ReactNode;
}

export const AppProvider = ({ children }: IAppProviderProps) => {
  const [userData, setUserData] = useState(DEFAULT_STATE.userData);
  const [currentStep, setCurrentStep] = useState(DEFAULT_STATE.currentStep);
  const [onboardingValues, setOnboardingValues] = useState(
    DEFAULT_STATE.onboardingValues,
  );
  const [errors, setErrors] = useState(DEFAULT_STATE.errors);

  useEffect(() => {
    setErrors({});
  }, [currentStep]);

  return (
    <AppContext.Provider
      value={{
        userData,
        currentStep,
        onboardingValues,
        errors,
        setUserData,
        setCurrentStep,
        setOnboardingValues,
        setErrors,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
