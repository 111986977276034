import React from "react";
import { MenuItem, TextField, Box } from "@mui/material";

interface IOption {
  value: string;
  label: string;
}

export interface ISelectInputProps {
  name: string;
  value: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  error?: boolean;
  onBlur: (e: React.FocusEvent<any>) => void;
  helperText?: string;
  options?: IOption[];
  caption: string;
  select?: boolean;
  placeholder?: string;
}

export default function FormInputItem({
  name,
  value,
  onChange,
  error,
  onBlur,
  helperText,
  options,
  caption,
  select = false,
  placeholder,
}: ISelectInputProps) {
  return (
    <Box>
      <Box className="text-blue-900 text-sm leading-5 text-left">{caption}</Box>
      <TextField
        name={name}
        select={select}
        label={value ? "" : placeholder || "Select"}
        fullWidth
        value={value}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        helperText={helperText}
        InputLabelProps={{
          focused: false,
          shrink: false,
        }}
        SelectProps={{
          MenuProps: {
            sx: {
              "& .MuiMenu-paper": {
                maxHeight: "50vh",
              },
            },
          },
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}
