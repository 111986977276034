import React, { useState } from "react";
import RequestDemoForm from "./components/RequestDemoForm";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Box } from "@mui/material";
import { isLightDOM } from "../utils";
import { segmentsTrack } from "../utils/analytics";

function AppRequestDemo() {
  const [requestSent, setRequestSent] = useState<boolean>(false);
  return (
    <div className="request-demo_modal">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        className="close-button"
        onClick={() => {
          const modal = isLightDOM()
            ? document.querySelector("#request-demo_overlay")
            : document
                .querySelector("#webflow_app")
                ?.shadowRoot?.querySelector("#request-demo_overlay");

          if (modal) {
            (modal as HTMLElement).style.display = "none";
          }
        }}
      >
        <g
          id="Request-campaign"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Request-Modal_1024-and-up"
            transform="translate(-1244.000000, -260.000000)"
          >
            <g id="Group-10" transform="translate(794.000000, 237.000000)">
              <g
                id="Icons-/-Close"
                transform="translate(450.000000, 23.000000)"
              >
                <rect
                  id="Rectangle"
                  fillOpacity="0"
                  fill="#D8D8D8"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                />
                <path
                  d="M18.7437076,6.49372922 C19.0854224,6.15202643 19.085432,5.59800707 18.7437292,5.25629236 C18.4020264,4.91457764 17.8480071,4.91456798 17.5062924,5.25627078 L12,10.7623711 L6.49370764,5.25627078 C6.15199293,4.91456798 5.59797357,4.91457764 5.25627078,5.25629236 C4.91456798,5.59800707 4.91457764,6.15202643 5.25629236,6.49372922 L10.7625416,11.9997864 L5.25629236,17.5058435 C4.91457764,17.8475463 4.91456798,18.4015657 5.25627078,18.7432804 C5.59797357,19.0849951 6.15199293,19.0850048 6.49370764,18.743302 L12,13.2372017 L17.5062924,18.743302 C17.8480071,19.0850048 18.4020264,19.0849951 18.7437292,18.7432804 C19.085432,18.4015657 19.0854224,17.8475463 18.7437076,17.5058435 L13.2374584,11.9997864 L18.7437076,6.49372922 Z"
                  id="Combined-Shape"
                  fill="#627283"
                  fillRule="nonzero"
                  opacity="0.3"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
      {requestSent ? (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="120"
            style={{ margin: "0 auto", marginBottom: "20px" }}
          >
            <g fill="none">
              <circle
                cx="60"
                cy="60"
                r="57.75"
                stroke="#00C070"
                strokeWidth="4.5"
              />
              <path
                fill="#00C070"
                d="M43.415 64.4a1.52 1.52 0 0 1-.415-.98c0-.28.138-.7.415-.98l1.939-1.96c.554-.56 1.384-.56 1.938 0l.139.14 7.615 8.26c.277.28.692.28.97 0l18.553-19.46h.139c.554-.56 1.384-.56 1.938 0l1.939 1.96c.553.56.553 1.4 0 1.96L56.43 76.58c-.277.28-.554.42-.97.42-.415 0-.692-.14-.969-.42l-10.8-11.76-.277-.42z"
              />
            </g>
          </svg>
          <Box className="text-blue-900 text-xxl leading-5 font-bold pb-6">
            Request Sent
          </Box>
          <p>
            Hang tight! Your message has been sent and someone from the Paved
            team will be back in touch ASAP.
          </p>
        </>
      ) : (
        <>
          <Box className="text-blue-900 text-xxl leading-5 font-bold pb-6">
            Request a Demo
          </Box>
          <p className="mb-3">
            Talk with a specialist about getting started with Paved.
          </p>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ?? ""}
          >
            <RequestDemoForm
              onSubmit={() => {
                setRequestSent(true);
                segmentsTrack("demo_requested");
              }}
            />
          </GoogleReCaptchaProvider>
        </>
      )}
    </div>
  );
}

export default AppRequestDemo;
