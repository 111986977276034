import React from "react";

function CheckCircle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120">
      <g fill="none">
        <circle cx="60" cy="60" r="57.75" stroke="#00C070" stroke-width="4.5" />
        <path
          fill="#00C070"
          d="M43.415 64.4a1.52 1.52 0 0 1-.415-.98c0-.28.138-.7.415-.98l1.939-1.96c.554-.56 1.384-.56 1.938 0l.139.14 7.615 8.26c.277.28.692.28.97 0l18.553-19.46h.139c.554-.56 1.384-.56 1.938 0l1.939 1.96c.553.56.553 1.4 0 1.96L56.43 76.58c-.277.28-.554.42-.97.42-.415 0-.692-.14-.969-.42l-10.8-11.76-.277-.42z"
        />
      </g>
    </svg>
  );
}

function Submitted() {
  return (
    <div className="h-96 max-h-screen flex flex-col justify-center items-center space-y-5">
      <CheckCircle />
      <div className="text-blue-900 text-xxl leading-5">
        Your Account is Being Reviewed
      </div>
      <div className="text-grey-600 pb-5">
        We will get back to you as quickly as possible.
      </div>
    </div>
  );
}

export default Submitted;
