import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import { Button, CircularProgress, MenuItem, TextField } from "@mui/material";

const VALIDATOR_URL_REQUIRED = yup
  .string()
  .matches(
    /^((http(s)?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-z]{2,})(\S*)?$/,
    "This value must be a valid URL.",
  )
  .required("This value is required.");

type SelectOption = {
  value: string;
  label: string;
};

type AccountType = "advertiser" | "publisher";

type AccountTypeOption = {
  value: AccountType;
  label: string;
};

type formData = {
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  url: string;
  account_type: string;
  "g-recaptcha-token": string;
  budget?: string;
  subscriber_range?: string;
};

type ServerError = {
  [key: string]: any;
};

const accountTypeOptions: AccountTypeOption[] = [
  {
    value: "advertiser",
    label: "I'm an advertiser",
  },
  {
    value: "publisher",
    label: "I'm a publisher",
  },
];

const inputsList: { name: string; caption: string }[] = [
  { name: "first_name", caption: "First Name" },
  { name: "last_name", caption: "Last Name" },
  { name: "email", caption: "Email" },
  { name: "company_name", caption: "Company Name" },
  { name: "site", caption: "Website" },
];

const budgetOptions: SelectOption[] = [
  "Less than $1,000",
  "$1,000 - $5,000",
  "$5,000 - $10,000",
  "$10,000 - $25,000",
  "$25,000+",
].map((value) => ({ value, label: value }));

const subscriberRangeOptions: SelectOption[] = [
  "Less than 5,000",
  "5,000 to 20,000",
  "20,000 to 50,000",
  "50,000 to 100,000",
  "100,000+",
].map((value) => ({ value, label: value }));

const initialValues = inputsList.reduce(
  (acc: { [key: string]: string }, item) => {
    acc[item.name] = "";
    return acc;
  },
  { budget: "", account_type: "", subscriber_range: "" },
);

const REQUIRED_VALUE = "This value is required";

const budgetSchema = yup.string().when(["account_type"], {
  is: (account_type: AccountType) => account_type === "advertiser",
  then: () => yup.string().required(REQUIRED_VALUE),
});

const subscriberRangeSchema = yup.string().when(["account_type"], {
  is: (account_type: AccountType) => account_type === "publisher",
  then: () => yup.string().required(REQUIRED_VALUE),
});

const keysDict: { [key: string]: string } = {
  account_type: "Account type",
  first_name: "First name",
  last_name: "Last name",
  email: "Email",
  company_name: "Company name",
  site: "Site",
  budget: "Budget",
  subscriber_range: "Subscriber range",
};

const validationSchema = yup.object().shape({
  account_type: yup.string().required(REQUIRED_VALUE),
  first_name: yup
    .string()
    .trim()
    .required(REQUIRED_VALUE)
    .max(30, "First name must be at most 30 characters")
    .matches(
      /^[a-zA-Z\-'\s]+$/,
      "Only Latin letters, dashes and apostrophes are allowed",
    ),
  last_name: yup
    .string()
    .trim()
    .required(REQUIRED_VALUE)
    .max(30, "Last name must be at most 30 characters")
    .matches(
      /^[a-zA-Z\-'\s]+$/,
      "Only Latin letters, dashes and apostrophes are allowed",
    ),
  email: yup
    .string()
    .email("Must be a valid e-mail")
    .required(REQUIRED_VALUE)
    .max(50, "Email must be at most 50 characters"),
  company_name: yup
    .string()
    .trim()
    .required(REQUIRED_VALUE)
    .max(50, "Company name must be at most 50 characters"),
  site: VALIDATOR_URL_REQUIRED.max(75, "Website must be at most 75 characters"),
  budget: budgetSchema,
  subscriber_range: subscriberRangeSchema,
});

export default function RequestDemoForm({
  onSubmit,
}: {
  onSubmit: () => void;
}) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    initialValues: { ...initialValues },
    onSubmit: async (values, { setErrors }) => {
      setLoading(true);
      const {
        first_name,
        last_name,
        email,
        company_name,
        site,
        account_type,
        budget,
        subscriber_range,
      } = values;

      const data: formData = {
        first_name,
        last_name,
        email: email.toLowerCase(),
        company_name: company_name,
        url: site.toLowerCase(),
        account_type: account_type,
        "g-recaptcha-token": "",
      };

      if (account_type === "advertiser") {
        data.budget = budget;
      } else {
        data.subscriber_range = subscriber_range;
      }

      // TODO: toast error if recaptcha isn't ready yet (that means service is unavailable)
      const token = executeRecaptcha
        ? await executeRecaptcha("yourAction")
        : "";

      data["g-recaptcha-token"] = token;

      axios
        .post("/api/v1/demo_requests", data)
        .then(onSubmit)
        .catch((resp) => {
          const errors = resp.response.data.error;
          const formikErrors = Object.keys(errors).reduce<ServerError>(
            (acc, key) => {
              acc[key] = `${keysDict[key] ?? ""} ${errors[key][0]}`;
              return acc;
            },
            {},
          );
          setErrors(formikErrors);
        })
        .finally(() => setLoading(false));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="w-full space-y-5">
      <TextField
        name="account_type"
        select
        label="Account Type"
        fullWidth
        value={formik.values.account_type}
        error={!!formik.errors.account_type && !!formik.touched.account_type}
        onChange={(event) => {
          formik.handleChange(event);
          formik.setFieldValue("account_type", event.target.value);
        }}
        onBlur={formik.handleBlur}
        helperText={
          formik.errors.account_type && formik.touched.account_type
            ? formik.errors.account_type.toString()
            : ""
        }
      >
        {accountTypeOptions?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {inputsList.map(({ name, caption }) => (
        <TextField
          key={name}
          name={name}
          label={caption}
          fullWidth
          value={formik.values[name]}
          error={!!formik.errors[name] && !!formik.touched[name]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={
            formik.errors[name] && formik.touched[name]
              ? formik.errors[name]?.toString()
              : ""
          }
        />
      ))}
      {formik.values.account_type === "advertiser" && (
        <TextField
          name="budget"
          select
          label="Ideal Campaign Budget"
          fullWidth
          value={formik.values.budget}
          error={!!formik.errors.budget && !!formik.touched.budget}
          onChange={(event) => {
            formik.handleChange(event);
            formik.setFieldValue("budget", event.target.value);
          }}
          onBlur={formik.handleBlur}
          helperText={
            formik.errors.budget && formik.touched.budget
              ? formik.errors.budget.toString()
              : ""
          }
        >
          {budgetOptions?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {formik.values.account_type === "publisher" && (
        <TextField
          name="subscriber_range"
          select
          label="How many subscribers do you have?"
          fullWidth
          value={formik.values.subscriber_range}
          error={
            !!formik.errors.subscriber_range &&
            !!formik.touched.subscriber_range
          }
          onChange={(event) => {
            formik.handleChange(event);
            formik.setFieldValue("subscriber_range", event.target.value);
          }}
          onBlur={formik.handleBlur}
          helperText={
            formik.errors.subscriber_range && formik.touched.subscriber_range
              ? formik.errors.subscriber_range.toString()
              : ""
          }
          InputLabelProps={{
            className: "select-label",
          }}
        >
          {subscriberRangeOptions?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      <Button
        type="submit"
        className="!normal-case"
        variant="contained"
        fullWidth
        size="large"
        disabled={loading}
        endIcon={
          loading ? <CircularProgress size={12} sx={{ color: "#fff" }} /> : null
        }
      >
        Request a Demo
      </Button>
    </form>
  );
}
