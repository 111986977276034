import { useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import { AppContext } from "../context";

const jwtAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Cookies.get("bearer")}`,
  },
});

export const useAxios = () => {
  const { setErrors } = useContext(AppContext);
  const setAuthToken = (bearer: string | null) => {
    if (bearer) {
      Cookies.set("bearer", bearer);
      jwtAxios.defaults.headers.common["Authorization"] = `Bearer ${bearer}`;
    } else {
      Cookies.remove("bearer");
      delete jwtAxios.defaults.headers.common["Authorization"];
    }
  };

  const requestApi = (method: string, uri: string, body?: any) => {
    const promise = new Promise<any>((resolve, reject) => {
      const config = {
        method: method.toLowerCase(),
        url: uri,
        data: body,
        headers: jwtAxios.defaults.headers.common,
      };
      jwtAxios
        .request(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const errorData = error.response?.data?.errors;
          setErrors(errorData);
          reject(error);
        });
    });
    return promise;
  };

  return {
    requestApi,
    setAuthToken,
  };
};
