import React, { useContext } from "react";
import { Box } from "@mui/material";

import { AppContext } from "../../../context";
import { UserType } from "../../../types";
import CodeVerificationForm from "./CodeVerificationForm";
import Submitted from "./Submitted";

function ThirdStepForm() {
  const { userData } = useContext(AppContext);

  return (
    <Box>
      {userData.userType === UserType.ADVERTISER ? (
        <Submitted />
      ) : (
        <CodeVerificationForm />
      )}
    </Box>
  );
}

export default ThirdStepForm;
