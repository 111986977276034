import { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { AppContext } from "../../../context/AppContext";
import { useAxios, useCustomFormik } from "../../../hooks";
import ExpiredStorage from "expired-storage";
import { getSecondsString } from "../../../utils";
import { UserType } from "../../../types";

export const expiredStorage = new ExpiredStorage();
export const LS_PHONE_VERIFY_DISABLE = "publisher_validate_phone_disable";
export const RESEND_DISABLE_PERIOD = 60;

const FormSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required("This value is required.")
    .test("isValidate", "Invalid phone number", (value) =>
      value ? isValidPhoneNumber(value) : false,
    ),
});

function PhoneVerifyForm() {
  const { userData, currentStep, setUserData, setCurrentStep } =
    useContext(AppContext);
  const { requestApi } = useAxios();
  const [loading, setLoading] = useState(false);
  const intervalId = useRef<NodeJS.Timeout>();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (userData.userType === UserType.PUBLISHER && currentStep === 2) {
      expiredStorage.clearExpired();
      const timeLeft = expiredStorage.getTimeLeft(LS_PHONE_VERIFY_DISABLE);
      if (!!timeLeft && timeLeft > 0) {
        setCount(timeLeft);
        startInterval();
      }
    } else {
      setCount(0);
    }
  }, [currentStep, userData]);

  const formik = useCustomFormik({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      const body = {
        phone_number: values.phoneNumber,
      };
      setLoading(true);
      requestApi("post", "/users/send_sms_code", body)
        .then(() => {
          expiredStorage.setItem(
            LS_PHONE_VERIFY_DISABLE,
            "",
            RESEND_DISABLE_PERIOD,
          );
          setUserData({
            ...userData,
            ...values,
          });
          setCurrentStep(currentStep + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (count <= 0) {
      stopInterval();
    }
  }, [count]);

  const startInterval = () => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);
    intervalId.current = interval;
  };

  const stopInterval = () => {
    if (!intervalId.current) return;
    clearInterval(intervalId.current);
    intervalId.current = undefined;
    setCount(-1);
  };

  return (
    <Box>
      <div className="text-blue-900 text-xxl leading-5 font-bold pb-6">
        Let's verify your account
      </div>
      <div className="text-grey-600 pb-5">
        Please enter your mobile number to verify your account
      </div>
      <form className="w-full space-y-5" onSubmit={formik.handleSubmit}>
        <div>
          <PhoneInput
            defaultCountry="US"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            className={`border ${
              formik.errors.phoneNumber && formik.touched.phoneNumber
                ? "border-red"
                : "border-blue-200"
            }`}
            onChange={(e) => formik.setFieldValue("phoneNumber", e)}
            onBlur={formik.handleBlur}
            placeholder="(201) 555-0123"
          />
          {formik.errors.phoneNumber && formik.touched.phoneNumber && (
            <div className="text-red text-xs pt-1 text-left">
              {formik.errors.phoneNumber.toString()}
            </div>
          )}
        </div>
        <Button
          type="submit"
          className="!normal-case"
          variant="contained"
          fullWidth
          size="large"
          disabled={count > 0 || loading}
          endIcon={
            loading ? (
              <CircularProgress size={12} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          Send Verification Code
        </Button>
        {count > 0 && (
          <Button
            variant="text"
            fullWidth
            size="large"
            disabled={true}
            sx={{ textTransform: "none" }}
          >
            {`Resend available in ${getSecondsString(count)}`}
          </Button>
        )}
      </form>
    </Box>
  );
}

export default PhoneVerifyForm;
