import { useContext, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  MenuItem,
  TextField,
} from "@mui/material";
import * as Yup from "yup";

import { AppContext } from "../../../context";
import { useAxios, useCustomFormik } from "../../../hooks";

const FormSchema = Yup.object().shape({
  goals: Yup.array().min(1, "Please select your advertising goals"),
  budget: Yup.string().required("Please select your ideal campaign budget."),
});

function GoalsAndBudgetForm() {
  const {
    userData,
    currentStep,
    onboardingValues,
    setUserData,
    setCurrentStep,
  } = useContext(AppContext);
  const { requestApi } = useAxios();
  const [loading, setLoading] = useState(false);
  const formik = useCustomFormik({
    initialValues: {
      goals: [],
      budget: undefined,
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      const body = {
        company_name: userData.companyName,
        url: userData.url,
        goals: values.goals,
        budget: values.budget,
      };
      setLoading(true);
      requestApi("patch", "/users/onboarding", body)
        .then(() => {
          setUserData({
            ...userData,
            ...values,
          });
          setCurrentStep(currentStep + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const goalTypes = useMemo(() => {
    return onboardingValues ? onboardingValues.goals : [];
  }, [onboardingValues]);
  const campaignBudgetTypes = useMemo(() => {
    return onboardingValues ? onboardingValues.budget : [];
  }, [onboardingValues]);

  return (
    <Box>
      <div className="text-blue-900 text-xxl leading-5 font-bold pb-6">
        What are your goals
      </div>
      <form className="w-full space-y-5" onSubmit={formik.handleSubmit}>
        <div>
          <ToggleButtonGroup
            className="!space-y-5"
            orientation="vertical"
            value={formik.values.goals}
            fullWidth
            onChange={(event, newValues) =>
              formik.setFieldValue("goals", newValues)
            }
          >
            {goalTypes.map((type) => (
              <ToggleButton
                key={type}
                className="!rounded !border-grey-500 !normal-case !text-blue-900 !font-bold text-left"
                classes={{
                  selected:
                    "!border-blue-500 !border-t !border-solid !bg-blue-200",
                }}
                value={type}
              >
                {type}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {formik.errors.goals && (
            <div className="text-red text-xs pt-1 text-left">
              {formik.errors.goals.toString()}
            </div>
          )}
        </div>
        <TextField
          name="budget"
          select
          label="Ideal Campaign Budget"
          fullWidth
          value={formik.values.budget}
          error={!!formik.errors.budget && !!formik.touched.budget}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={
            formik.errors.budget && formik.touched.budget
              ? formik.errors.budget.toString()
              : ""
          }
        >
          {campaignBudgetTypes.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <Button
          type="submit"
          className="!normal-case"
          variant="contained"
          fullWidth
          size="large"
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress size={12} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          Continue
        </Button>
      </form>
    </Box>
  );
}

export default GoalsAndBudgetForm;
