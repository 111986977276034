import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import "./index.scss";
import App, { AppId } from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./context";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { mainCSS } from "./main.css";
import { isLightDOM } from "./utils";

function showRequestDemo() {
  const modal = isLightDOM()
    ? document.querySelector("#request-demo_overlay")
    : document
        ?.querySelector("#webflow_app")
        ?.shadowRoot?.querySelector("#request-demo_overlay");

  if (modal) {
    (modal as HTMLElement).style.display = "block";
  }
}

const showModalTriggers = document?.querySelectorAll("[data-book-demo]");

showModalTriggers.forEach((element) =>
  element.addEventListener("click", showRequestDemo),
);

const useLightDOM = isLightDOM();

const rootContainer = document.querySelector("#webflow_app");

const appIds = rootContainer
  ?.getAttribute("data-apps")
  ?.split(",")
  ?.map((id) => id.trim());

const shadowRootElement = document.createElement("div");
const emotionRoot = document.createElement("style");

const container = useLightDOM
  ? rootContainer
  : rootContainer?.attachShadow({ mode: "open" });

if (!useLightDOM) {
  const stylesRoot = document.createElement("style");
  stylesRoot.innerHTML = mainCSS;
  container?.appendChild(stylesRoot);
}

container?.appendChild(emotionRoot);
container?.appendChild(shadowRootElement);

const cache = createCache({
  key: "css",
  prepend: true,
  container: emotionRoot,
});

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          background: "#0e63f4 !important",
          "&:hover": {
            background: "#185adf !important",
          },
          ":disabled": {
            color: "#fff",
            backgroundColor: "#467fcf",
            opacity: 0.65,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#f8faff",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1976d2",
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#d32f2f",
          },
        },
        notchedOutline: {
          borderColor: "#ecf2fe",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          textAlign: "left",
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        container: shadowRootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: shadowRootElement,
      },
    },
    MuiModal: {
      defaultProps: {
        container: shadowRootElement,
      },
    },
  },
  typography: {
    fontFamily: "'Geomanist webfont', sans-serif",
  },
  breakpoints: {
    values: {
      xs: 300, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens
    },
  },
});

if (!!appIds?.length) {
  ReactDOM.createRoot(shadowRootElement).render(
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <App appIds={appIds as AppId[]} />
        </AppProvider>
      </ThemeProvider>
    </CacheProvider>,
  );

  reportWebVitals();
}
