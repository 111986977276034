import React, { useContext } from "react";
import { Box } from "@mui/material";

import { AppContext } from "../../../context";
import { UserType } from "../../../types";
import GoalsAndBudgetForm from "./GoalsAndBudgetForm";
import PhoneVerifyForm from "./PhoneVerifyForm";

function ThirdStepForm() {
  const { userData } = useContext(AppContext);

  return (
    <Box>
      {userData.userType === UserType.ADVERTISER ? (
        <GoalsAndBudgetForm />
      ) : (
        <PhoneVerifyForm />
      )}
    </Box>
  );
}

export default ThirdStepForm;
